/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  /*
   * Stop fixed element when it reaches the footer
   */
  function constrainFixedScroll(el) {
    var $el = $(el);
    var windowHeight = $(window).height();
    var percentBottom = $el.data("offset-bottom-percent");
    var footerVisible = ( windowHeight - $('footer.content-info').offset().top ) + $(window).scrollTop();
    var fixedPosition = windowHeight * ( percentBottom / 100 );
    if( $el.hasClass('fixed') ) {
      fixedPosition = fixedPosition + $el.outerHeight();
    }
    var delta = footerVisible - fixedPosition;

    if( delta > 0 ) {
      $el.removeClass('fixed').css({'bottom': '0%'});
    } else {
      $el.addClass('fixed').css({'bottom': percentBottom + '%'});
    }
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() { // JavaScript to be fired on all pages
        $(document).ready(function(){
          /*
           * Stickyfill Polyfill
           */
          $('.sticky-top').Stickyfill();

          /*
           * Smooth Scroll
           */
          $('a[href^="#"]').on('click',function (e) {
              e.preventDefault();

              var target = this.hash;
              var $target = $(target);

              $('html, body').stop().animate({
                  'scrollTop': $target.offset().top
              }, 500, 'swing', function () {
                if( !$target.is('#scrolltop') ){
                  window.location.hash = target;
                }
              });
          });
        });

        $(window).on("orientationchange load resize scroll", function () {
          constrainFixedScroll('.floating-cta');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        // Splash slideshow
        function loadSplashSlideshow() {
          splashSlideshow = $('#splash-slideshow').bxSlider({
            mode: 'fade',
            speed: 666,
            touchEnabled: false,
            pager: false,
            controls: false,
            auto: true,
            pause: 4000,
            autoDelay: 500,
            onSlideAfter: function($slideElement, oldIndex, newIndex) {
              // @TODO: Put transition effects here!
            }
          });
        }

        // Header Offset
        function headerOffset() {
          var offset = 0;
          if( $('header.banner').hasClass('sticky-top') || $('header').hasClass('fixed-top') ) {
            offset = $('header.banner').outerHeight();
          }
          return offset;
        }


        /*
         * fullPage.js
         */
        var isTouchDevice = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|playbook|silk|BlackBerry|BB10|Windows Phone|Tizen|Bada|webOS|IEMobile|Opera Mini)/);
        var isAutoScroll  = true,
            setAsf;
        // JavaScript to be fired on the home page
        $(document).ready(function() {

          // fullPage.js
          $('#fullpage').fullpage({
            verticalCentered: false,
            scrollingSpeed: 700,
            autoScrolling: isAutoScroll,
            scrollBar: true,
            css3: true,
            fitToSection: false,
            navigation: true,
            navigationPosition: 'right',
            navigationTooltips: ['Introduction', 'Step One', 'Step Two', 'Step Three'],
            slideSelector: '.fpslider-slide',
            offsetSections: true,
		offsetSectionsKey: 'YXZlbnVlcnVncy5jb21fdGJBYjJabWMyVjBVMlZqZEdsdmJuTT1RUUM=',
//            offsetSectionsKey: 'd2VzdGd1YXJkc29sdXRpb25zLmNvbV8xMEFiMlptYzJWMFUyVmpkR2x2Ym5NPTRBaA==',
            afterRender: function() {
              loadSplashSlideshow();
              //$(this).children('.fullpage').css({'padding-top': headerOffset() + 'px'});
            },
            afterResize: function(){
              //$(this).children('.fullpage').css({'padding-top': headerOffset() + 'px'});
            },
            onLeave: function(index, newIndex, direction){
              // Pause/Unpause Splash Slideshow
              if( newIndex === 2 && direction === 'down' ) {
                splashSlideshow.stopAuto();
              } else if( newIndex === 1 && direction === 'up' ) {
                splashSlideshow.goToNextSlide();
                splashSlideshow.startAuto();
              }

              clearTimeout(setAsf);
              //console.log('index: ' + index + ' newIndex: ' + newIndex + ' direction: ' + direction + ' isAutoScroll: ' + isAutoScroll);
              if( newIndex === 5 && isAutoScroll === true ) {
                if( direction === 'up' ) {
                  $.fn.fullpage.moveTo(4);
                  //console.log('MT4 & RF');
                  $('#fp-nav').fadeIn();
                  return false;
                } else {
                  // Do immediately
                  $.fn.fullpage.setAutoScrolling(false);
                  isAutoScroll = false;
                  //console.log('ASF 4TO5');
                  $('#fp-nav').fadeOut();
                  return false;
                }
              } else if( newIndex > 3 && direction === 'down' && isAutoScroll === true ) {
                // Going down, wait for scroll transition to finish
                setAsf = setTimeout(function() {
                  $.fn.fullpage.setAutoScrolling(false);
                  isAutoScroll = false;
                  //console.log('ASF (newIndex > 3)');
                  $('#fp-nav').fadeOut();
                  return false;
                }, 750);
              } else if( (index >= 5 || newIndex <= 3) && direction === 'up' && isAutoScroll === false ) {
                $.fn.fullpage.setAutoScrolling(true);
                isAutoScroll = true;
                //console.log('AST');
                $('#fp-nav').fadeIn();
                return false;
              }
            }
          });


          /*
           * BX-Slider
           */

          // Projects carousel
          $('#projects-carousel .carousel-parent > .carousel').bxSlider({
            minSlides: 1,
            maxSlides: 3,
            slideWidth: 372,
            slideMargin: 30,
            prevSelector: '#projects-carousel > .carousel-control-prev',
            nextSelector: '#projects-carousel > .carousel-control-next',
            nextText: '<span class="carousel-control-next-icon" aria-hidden="true"></span><span class="sr-only">Next</span>',
            prevText: '<span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="sr-only">Previous</span>',
            touchEnabled: false
          });

          // Individual Project Images
          $('#projects-carousel .carousel-child > .carousel').each(function() {
            var sliderTransitioning = false,
            slider = $(this).bxSlider({
              infiniteLoop: false,
              controls: false,
              onSlideBefore: function() {
                sliderTransitioning = true;
              },
              onSlideAfter: function() {
                sliderTransitioning = false;
              }
            });

            // Project Image Hover Effects
            $(this).hover(function() {
              if( sliderTransitioning === false ) {
                if( slider.getCurrentSlide() === 0 ){
                  slider.goToSlide(1);
                } else {
                  slider.goToSlide(0);
                }
              }
            }, function() {
              if( sliderTransitioning === false ) {
                if( slider.getCurrentSlide() === 1 ){
                  slider.goToSlide(0);
                } else {
                  slider.goToSlide(1);
                }
              }
            });
          });

          // Testimonials Carousel
          $('#testimonials-carousel .carousel').bxSlider({
            prevSelector: '#testimonials-carousel .carousel-control-prev',
            nextSelector: '#testimonials-carousel .carousel-control-next',
            nextText: '<span class="carousel-control-next-icon" aria-hidden="true"></span><span class="sr-only">Next</span>',
            prevText: '<span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="sr-only">Previous</span>',
            adaptiveHeight: false,
            touchEnabled: true
          });

        }); // document.ready
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Gallery
  $('.thumbnails li a').click(function() {
      var thumbnail = $(this);
      var count = thumbnail.attr('data-count');

      $('.featured a').hide();
      $('.featured a[data-count="' + count + '"]').show();

      return false;
  });

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
